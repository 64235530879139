import Box, { BoxProps } from "@mui/material/Box";
import Container from "@mui/material/Container";
import React, { PropsWithChildren } from "react";

export interface SectionProps extends BoxProps {
  sectionId?: string;
  disablePadding?: boolean;
}

export const Section = (props: PropsWithChildren<SectionProps>) => {
  const { sectionId, disablePadding, children, ...otherProps } = props;

  return (
    <Box id={sectionId} paddingY={{ xs: 4, md: 10 }} component="section" {...otherProps}>
      <Container maxWidth={disablePadding ? false : "lg"} disableGutters={disablePadding}>
        {children}
      </Container>
    </Box>
  );
};
