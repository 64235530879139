import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { Section } from "../../components/core/Section";
import { SmartLink } from "../../components/core/SmartLink";
import { Layout } from "../../components/layout/layout";
import { socialLinks } from "../../data/links";

const ContactSuccessPage = () => {
  return (
    <Layout>
      <Section>
        <Box textAlign="center">
          <Typography component="h1" variant="h3">
            We will be in touch shortly
          </Typography>
          <Typography variant="body1" sx={{ marginTop: { xs: 2, md: 3 } }}>
            Meanwhile you can check our{" "}
            <SmartLink path={socialLinks.artStation.path} external={true} sx={{ color: "primary.main" }}>
              ArtStation
            </SmartLink>{" "}
            page to see our projects
          </Typography>
        </Box>
      </Section>
    </Layout>
  );
};

export default ContactSuccessPage;
